.HeaderLogo {
    /* text configuration */
    color: white;
    font-size: 5em;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-image: url("background_image.jpg");
    background-size: cover;
    min-height: 55vh;
}
@media (max-width: 800px) {
    .HeaderLogo {
        flex-direction: column;
    }
}
.Logo {
    font-size: 16px;
    width: 15rem;
}
img {
    max-width: 100%;
    height: auto;
}