.BodyFlex {
    display: flex;
    flex-flow: column;
    height: 45vh;
}
.ExecutiveSummary {
    background-color: #222;
    color: #eee;
    flex: 1 1 auto;
}
.SummaryText {
    font-size: 1.5rem;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
@media (max-width: 850px) {
    .SummaryText {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

/*
Flexbox reference
.box {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  
.box .row {
    border: 1px dotted grey;
}
.box .row.header {
    flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
  }
  
  .box .row.content {
    flex: 1 1 auto;
  }
  
  .box .row.footer {
    flex: 0 1 40px;
}*/